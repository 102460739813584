<template>
  <section class="main-products swiper-slide">
    <div class="section-content">
      <h2 class="main-products__title section-title" v-html="content.offer_two.title"></h2>
      <div class="products-wrapper">
        <img
          src="@/assets/images/items/item1.png"
          alt="product"
          class="product_image1"
          data-swiper-parallax="-200"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item2.png"
          alt="product"
          class="product_image2"
          data-swiper-parallax="-100"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item3.png"
          alt="product"
          class="product_image3"
          data-swiper-parallax="-600"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item4.png"
          alt="product"
          class="product_image4"
          data-swiper-parallax="-400"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item5.png"
          alt="product"
          class="product_image5"
          data-swiper-parallax="-300"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item6.png"
          alt="product"
          class="product_image6"
          data-swiper-parallax="-400"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item5.png"
          alt="product"
          class="product_image7"
          data-swiper-parallax="-500"
          data-swiper-parallax-duration="2000" />
      </div>
      <div class="btn-row" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <a class="main-products__btn btn-style" @click="openQuiz">{{ content.offer_two.text_btn }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Slide_2",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  methods: {
    ...mapMutations(["openQuiz"]),
  },
};
</script>

<style></style>
