<template>
  <div id="main-slider" class="main-slider swiper">
    <div class="swiper-wrapper">
      <Slide_1 />
      <Slide_2 />
      <Slide_3 />
      <Slide_4 />
      <Slide_5 />
      <Slide_6 />
      <Slide_7 />
    </div>
  </div>
  <transition name="fade">
    <PopupQuiz v-if="this.$store.state.statusQuiz" />
  </transition>
  <transition name="fade">
    <IndexPopup v-if="this.$store.state.popup.status == 'info'" />
  </transition>
  <transition name="fade">
    <VideoPopup v-if="this.$store.state.popup.status == 'video'" />
  </transition>
  <transition name="fade">
    <FormPopup v-if="this.$store.state.popup.status == 'form'" />
  </transition>
</template>

<script>
import Slide_1 from "@/components/slide_1.vue";
import Slide_2 from "@/components/slide_2.vue";
import Slide_3 from "@/components/slide_3.vue";
import Slide_4 from "@/components/slide_4.vue";
import Slide_5 from "@/components/slide_5.vue";
import Slide_6 from "@/components/slide_6.vue";
import Slide_7 from "@/components/slide_7.vue";
import PopupQuiz from "@/components/section/quiz/PopupQuiz.vue";
import IndexPopup from "@/components/section/popup/IndexPopup";
import FormPopup from "@/components/section/popup/FormPopup";
import VideoPopup from "@/components/section/popup/VideoPopup";
import Swiper, { Parallax, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

export default {
  components: {
    PopupQuiz,
    IndexPopup,
    VideoPopup,
    FormPopup,
    Slide_1,
    Slide_2,
    Slide_3,
    Slide_4,
    Slide_5,
    Slide_6,
    Slide_7,
  },
  mounted() {
    var touchstartY = 0;
    var touchendY = 0;
    let changeSlider = false;

    /* main slider */
    var mainSlider = new Swiper("#main-slider", {
      modules: [Parallax, Mousewheel],
      speed: 1800,
      parallax: true,
      direction: "vertical",
      watchSlidesProgress: true,
      mousewheelControl: true,
      mousewheel: true,
      allowTouchMove: false,
      on: {
        slideChange: function slideChange(e) {
          if (e.activeIndex == 0) {
            window.$("#kid").attr("data-position", 0);
          }
          if (e.activeIndex == 1) {
            window.$("#kid").attr("data-position", 1);
          }
          if (e.activeIndex == 2) {
            window.$("#kid").attr("data-position", 2);
            setTimeout(function () {
              // qualitySlider.autoplay.start();
            }, 3500);
          }
        },
        slideChangeTransitionStart: () => {
          changeSlider = true;
        },
        slideChangeTransitionEnd: () => {
          changeSlider = false;
        }
      },
    });
    /* swipe detection */
    window.$(window).on("touchstart", function (event) {
      touchstartY = event.changedTouches[0].screenY;
    });
    window.$(window).on("touchend", function (event) {
      touchendY = event.changedTouches[0].screenY;
      handleGesure();
    });
    function handleGesure() {
      if (!window.$("body").hasClass("overflow")) {
        if (touchendY < touchstartY && touchstartY - touchendY > 150) {
          mainSlider.slideNext();
        }
        if (touchendY > touchstartY && touchendY - touchstartY > 150) {
          mainSlider.slidePrev();
        }
      }
    }

    window.$(window).on("keydown", function (event) {
      if (event.keyCode == '38') {
        if(!changeSlider) {
          mainSlider.slidePrev();
        }
      }

      if (event.keyCode == '40') {
        if(!changeSlider) {
          mainSlider.slideNext();
        }
      }
    })
  },
};
</script>

<style>
.page__offer,
.page__example-product,
.page__catalog,
.page__advantages {
  margin-bottom: 170px;
}

.page__about {
  margin-bottom: 100px;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .page__offer,
  .page__example-product,
  .page__catalog,
  .page__advantages {
    margin-bottom: 150px;
  }

  .page__about {
    margin-bottom: 80px;
  }
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .page__offer,
  .page__example-product,
  .page__catalog,
  .page__advantages {
    margin-bottom: 130px;
  }

  .page__about {
    margin-bottom: 70px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .page__offer,
  .page__example-product,
  .page__catalog,
  .page__advantages {
    margin-bottom: 100px;
  }

  .page__about {
    margin-bottom: 60px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .page__offer,
  .page__example-product,
  .page__catalog,
  .page__advantages {
    margin-bottom: 80px;
  }

  .page__about {
    margin-bottom: 40px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .page__offer,
  .page__example-product,
  .page__catalog,
  .page__advantages {
    margin-bottom: 60px;
  }

  .page__about {
    margin-bottom: 30px;
  }
}
</style>
