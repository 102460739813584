<template>
  <div class="popup-contact-good">
    <H2 class="popup-contact-good__title" v-html="data.Title" />
    <div class="gift-block">
      <div class="gift-value">
        <p>Промокод<span>7%</span>скидка</p>
      </div>
    </div>
    <p class="popup-contact-good__text" v-html="data.subtitle"></p>
    <Button
      class="popup-contact-good__button"
      :href="link && link['ergomotion']"
      tag="a"
      target="_blank"
      v-if="link && link['ergomotion']"
      >{{ data.text_btn }}</Button
    >
    <div class="i-flocktory" data-fl-action="exchange" :data-fl-user-email="email" :data-fl-user-name="name"></div>
  </div>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Button from "@/components/element/ButtonApp.vue";

export default {
  components: {
    H2,
    Button,
  },
  data() {
    return {
      data: this.$store.state.dataQuiz.quizResponse.successful_submission || {},
      email: this.$store.state.userData.email || "",
      name: this.$store.state.userData.name || "",
      link: this.$store.state.linkQuiz,
    };
  },
  methods: {
    uuid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  },
  mounted() {
    console.log('FLOCKTORY MOUNTED');

    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
    });

    console.log(uuid);

    window.flocktory = window.flocktory || [];
    window.flocktory.push(['postcheckout',
     {
        user: {
          name: this.$store.state.userData.name,
          email: this.$store.state.userData.email,
        }, 
        order: {
          id: uuid,
          price: 0,
        } 
    }]); 
  }
};
</script>

<style>
.popup-contact-good {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.popup-contact-good__title {
  color: #ffffff;
  display: block;
  width: 100%;
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
}

.popup-contact-good .gift-block {
  position: relative !important;
  margin-bottom: 40px !important;
}

.popup-contact-good .gift-block .gift-value {
  position: static !important;
  width: 132px !important;
  height: 132px !important;
  background-color: rgba(39, 151, 157, 0.95);
}

.popup-contact-good .gift-block .gift-value span {
  margin: 1px 0 18px !important;
}

.popup-contact-good__text {
  margin-bottom: 50px;
  max-width: 525px;
  font-family: Exo 2, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  text-align: center;
}

.popup-contact-good__button {
  background-color: #ffdf68;
  color: black;
  margin-bottom: 30px;
}

.popup-contact-good__button:hover {
  background-color: #fdd74e;
}

.popup-contact-good__link {
  text-align: center;
  color: #ffffff;
}

@media (max-width: 1440.98px) {
  .popup-contact-good__title {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .popup-contact-good .gift-block {
    margin-bottom: 30px !important;
  }

  .popup-contact-good .gift-block .gift-value {
    width: 120px !important;
    height: 120px !important;
  }

  .popup-contact-good__text {
    margin-bottom: 30px;
    width: 100%;
  }

  .popup-contact-good__button {
    margin-bottom: 30px;
  }
}
</style>
