<template>
  <button title="Закрыть" type="button" class="mfp-close"><i class="icon icon-close"></i></button>
</template>

<script>
export default {
  name: "ButtonClose",
};
</script>

<style>
.mfp-close .icon-close {
  color: #ffffff;
}

.mfp-close__black .icon-close {
  color: #000000;
}
</style>
