import { createApp } from "vue";
import App from "./App.vue";
import Store from "@/store/index.js";
import VueScrollTo from "vue-scrollto";
import SimpleBar from "simplebar";
import AOS from "aos";
import mediaWp from "@/plugins/mediaWp.js";
// import VueYandexMetrika from "vue3-yandex-metrika";
import "simplebar/dist/simplebar.css";
import "aos/dist/aos.css";
// import "@/assets/css/st-global-v1.css";
import "@/assets/css/main.css";
import "@/assets/css/styles.css";

window.apiSt = "https://kids.askona.ru/api";

let app = createApp(App)
  .use(Store)
  .use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  })
  .use(mediaWp);

// Подключение метрики
// if (window.settingsApp.metrika) {
//   app.use(VueYandexMetrika, {
//     id: null,
//     env: "production",
//     options: {
//       clickmap: true,
//       trackLinks: true,
//       accurateTrackBounce: true,
//       webvisor: true,
//     },
//   });
// }

// Инициализация библиотек
AOS.init({ once: true });

if (window.innerWidth > 991) {
  window.simpleBar = new SimpleBar(document.querySelector("body"));
  window.simpleBar.getScrollElement().addEventListener("scroll", () => AOS.refresh());
  document.body.style.maxHeight = "100vh";
}

app.mount("#app");
