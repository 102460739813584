<template>
  <div class="input-wrapper">
    <input
      class="input-wrapper__input"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('castomBlur')"
      :placeholder="placeholder"
      :disabled="disabled" />
  </div>
</template>

<script>
export default {
  props: ["modelValue", "disabled", "placeholder"],
  emits: ["update:modelValue", "castomBlur"],
};
</script>

<style>
.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
}

.input-wrapper__label {
  margin-left: 15px;
  margin-bottom: 10px;

  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #565656;

  transition: color 0.4s;
}

.error .input-wrapper__label {
  color: rgb(82, 0, 0);
}

.input-wrapper__input {
  margin-bottom: 30px;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  border-radius: 50px;

  width: 460px;
  display: block;
  height: 58px;
  transition: border-color 0.4s;
  color: #ffffff;
}

.input-wrapper_big .input-wrapper__input {
  width: 520px;
}

.input-wrapper__input::placeholder {
  color: #ffffff;
}

.input-wrapper__input:focus {
  outline-color: #00a7b5;
}

.error .input-wrapper__input {
  border-color: rgb(184, 0, 0);
}

.input-wrapper__input[type="text"] {
  padding-left: 20px;

  font-family: Exo2, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1440.98px) {
  .input-wrapper__input {
    width: 100%;
    height: 50px;
  }

  .input-wrapper__input[type="text"] {
    font-size: 14px;
  }

  .input-wrapper_big,
  .input-wrapper {
    width: 100%;
  }

  .input-wrapper_big .input-wrapper__input {
    width: 100%;
    height: 50px;
  }
}
</style>
