<template>
  <button class="button-prev">
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.64645 15.3536C7.84171 15.5488 8.15829 15.5488 8.35355 15.3536C8.54882 15.1583 8.54882 14.8417 8.35355 14.6464L7.64645 15.3536ZM0.999999 8L0.646446 7.64645L0.292893 8L0.646446 8.35355L0.999999 8ZM8.35355 1.35355C8.54881 1.15829 8.54881 0.841708 8.35355 0.646446C8.15829 0.451184 7.84171 0.451184 7.64645 0.646446L8.35355 1.35355ZM8.35355 14.6464C6.98672 13.2796 5.23672 11.5296 3.82843 10.1213C3.12428 9.41718 2.50556 8.79846 2.06288 8.35577C1.84154 8.13443 1.66421 7.9571 1.54221 7.83511C1.48121 7.7741 1.43405 7.72694 1.40213 7.69503C1.38618 7.67907 1.37403 7.66692 1.36587 7.65877C1.36179 7.65468 1.35872 7.65162 1.35666 7.64955C1.35565 7.64855 1.35482 7.64772 1.35433 7.64723C1.35413 7.64703 1.35382 7.64671 1.35375 7.64664C1.35368 7.64658 1.35363 7.64653 1.3536 7.6465C1.35357 7.64646 1.35355 7.64645 0.999999 8C0.646446 8.35355 0.646462 8.35357 0.646495 8.3536C0.646527 8.35364 0.646576 8.35368 0.646641 8.35375C0.646799 8.35391 0.646938 8.35405 0.647224 8.35433C0.647759 8.35487 0.648502 8.35561 0.649548 8.35666C0.651595 8.3587 0.654705 8.36181 0.658768 8.36588C0.666927 8.37404 0.679066 8.38617 0.695026 8.40213C0.726941 8.43405 0.774106 8.48121 0.835104 8.54221C0.957101 8.66421 1.13443 8.84154 1.35577 9.06288C1.79846 9.50556 2.41717 10.1243 3.12132 10.8284C4.52961 12.2367 6.27961 13.9867 7.64645 15.3536L8.35355 14.6464ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646446L0.646446 7.64645L1.35355 8.35355Z"
        fill="white" />
    </svg>
  </button>
</template>

<script>
export default {
  props: ["parentClass"],
  data() {
    return {
      classObject: {
        button: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button-prev {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 3px solid #99DCE1;
  border-radius: 50%;

  background-color: #00a7b5;

  transition: border-width 0.6s;

  cursor: pointer;
}

.button-prev.disabled {
  border-color: #d9d9d9;
}

.button-prev:hover {
  border-width: 0px;
}

.button-prev_empty {
  width: 59px;
  height: 59px;
  border-width: 1px;
  border-color: #00a7b5;

  background-color: unset;
}

.button-prev_empty svg path {
  fill: #00a7b5;
}

.button-prev.disabled svg path {
  fill: #d9d9d9;
}

.button-prev_empty:hover {
  border-width: 1px;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .button-prev {
    width: 52px;
    height: 52px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .button-prev {
    width: 46px;
    height: 46px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {}
</style>