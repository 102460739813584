<template>
  <section class="modal-content__step" data-step="1">
    <div class="modal-title section-title" v-html="data.title"></div>
    <div class="modal-subtitle" v-html="data.subtitle"></div>
    <a class="test-link btn-style" @click="$emit('start')">{{ data.text_btn }}</a>
    <img src="@/assets/images/ancors/rocket.png" alt="rocket" class="rocket-image" />
    <a :href="data.doc_file" class="info-link" target="_blank">{{ data.doc_option }}</a>
  </section>
</template>

<script>
export default {
  name: "PopupStart",
  props: {
    data: {
      require: true,
      default: () => ({}),
    },
  },
  emits: ["start"],
  components: {},
};
</script>

<style>
.popup-start {
  display: flex;
  width: 100%;
}

.popup-start__image {
  flex-grow: 1;

  background-color: #00b3b9;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.popup-start__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 44.5vw;
  padding-left: 70px;

  background-color: #ffffff;
}

.popup-start__text {
  max-width: 400px;
  width: 100%;
}

.popup-start__button {
  width: fit-content;
  margin-bottom: 30px;
}

.popup-start__link {
  width: 100%;
  max-width: 325px;
  font-size: 12px;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .popup-start__content {
    padding-left: 60px;
  }
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .popup-start__content {
    padding-left: 50px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .popup-start__content {
    padding-left: 40px;
  }

  .popup-start__image {
    background-size: contain;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .popup-start__content {
    padding-left: 30px;
  }

  .popup-start {
    flex-direction: column;
  }

  .popup-start__image {
    flex-shrink: 0;
    margin-bottom: 40px;

    background-size: cover;
  }

  .popup-start__image::after {
    content: "";

    display: block;
    padding-bottom: 55%;
  }

  .popup-start__content {
    justify-content: start;
    width: auto;
    min-height: 282px;
    height: 30vh;
  }

  .popup-start__button {
    width: 203px;
    margin-bottom: 15px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .popup-start__content {
    padding-left: 13px;
    padding-right: 13px;
  }
}

@media (max-width: 549.98px) {
  .popup-start__content {
    height: 50vh;
  }
}
</style>
