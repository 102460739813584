export function validation(value, type) {
  if (value == "") return false;

  if (type == "phone") {
    let pattern = /^\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d$/;
    let formatPhone = value.replace(/[^0-9]/g, "");

    if (formatPhone[1] !== "9") {
      return false;
    }

    return pattern.test(value);
  }

  if (type == "email") {
    let pattern = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;

    return pattern.test(value);
  }

  return true;
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function makeXHR(addr, fd, resp, onerror = null) {
  const xhr = new XMLHttpRequest();

  xhr.onload = () => {
    resp(xhr);
  };

  xhr.onerror = onerror;

  xhr.open("POST", addr);
  xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

  xhr.send(fd);

  return xhr;
}

/* eslint-disable */
export function getCookie(name) {
  let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}
