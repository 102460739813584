<template>
  <component class="btn-style" :class="classButton" :is="tag">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      // Определяет возвращаемый тег, по умолчанию button
      type: String,
      default: "button",
    },
    classButton: {
      // Определяет дополнительный класс, по умолчанию ''
      type: String,
      default: "",
    },
  },
  data() {
    return {
      classObject: {
        button: true,
        [this.parentClass]: this.parentClass,
      },
    };
  },
};
</script>

<style>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 60px;
  border: none;

  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  white-space: nowrap;

  background-color: #00a7b5;
  border-radius: 30px;

  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #00848f;
}

.btn-style:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
  background-color: #09d3c6 !important;
  color: #ffffff !important;
  box-shadow: 0 8px 15px rgba(9, 211, 198, 0.55) !important;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .button {
    font-size: 14px;
  }
}

/*Контейнер  992-1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .button {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .button {
    padding: 15px 50px;
  }
}
</style>
