<template>
  <div class="popup-form modal-style" @click.self="closePopup">
    <div class="popup-form__wrapper modal-content">
      <ButtonClose class="popup__close" @click="closePopup" />
      <div class="popup-form__title">Отправить свою историю</div>
      <Input
        class="popup-form__input input-wrapper_big"
        :class="errors.name ? 'error' : ''"
        v-model="name"
        @castom-blur="validation(name, 'name')"
        placeholder="Ваше имя* "></Input>
      <Input
        class="popup-form__input input-wrapper_big"
        :class="errors.city ? 'error' : ''"
        v-model="city"
        @castom-blur="validation(city, 'city')"
        placeholder="Ваше город* "></Input>
      <Input
        class="popup-form__input input-wrapper_big"
        :class="errors.age ? 'error' : ''"
        v-model="age"
        @castom-blur="validation(age, 'age')"
        placeholder="Возраст ребенка* "></Input>
      <InputPhone
        class="popup-form__input input-wrapper_big"
        :class="errors.phone ? 'error' : ''"
        v-model="phone"
        @castom-blur="validation(phone, 'phone')"
        :disabled="$store.state.userData.phone"
        placeholder="Телефон*"></InputPhone>
      <Input
        class="popup-form__input input-wrapper_big"
        :class="errors.email ? 'error' : ''"
        v-model="email"
        @castom-blur="validation(email, 'email')"
        placeholder="E-mail*"></Input>
      <div class="popup-form__file file-group">
        <label>
          <span class="label">
            <i class="icon icon-file"></i>
            <span class="file-group__indicator" :style="'--loader:' + progress / 100" v-if="statusVideo == 'loading'"></span>
            <span v-if="statusVideo == 'not-loaded'">Прикрепить видео (до 1 минуты, до 250мб, mp4, ogv, mov, webm)</span>
            <span v-if="statusVideo == 'loaded'">{{ fileName }}</span>
          </span>
          <input type="file" accept=".mp4,.ogv, .mov, .webm" @change="uploadFile" />
        </label>
      </div>
      <Button class="popup-form__button" :disabled="!videoLink" @click="submitButton">Отправить</Button>
    </div>
  </div>
</template>

<script>
import ButtonClose from "@/components/element/ButtonClose.vue";
import { mapState } from "vuex";
import Button from "@/components/element/ButtonApp.vue";
import Input from "@/components/element/InputApp.vue";
import InputPhone from "@/components/element/InputPhone.vue";
import { validation } from "@/helpers/index.js";
import axios from "axios";
import md5 from "md5";

export default {
  name: "PopupApp",
  components: {
    ButtonClose,
    Button,
    Input,
    InputPhone,
  },
  computed: mapState({
    popup: "popup",
  }),
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      city: "",
      age: "",
      errors: {
        name: false,
        phone: false,
        email: false,
        city: false,
        age: false,
      },
      videoLink: false,
      statusVideo: "not-loaded",
      fileName: "",
      progress: 0,
    };
  },
  methods: {
    validation(value, type = null) {
      this.errors[type] = !validation(value, type);
    },
    submitButton() {
      let isSubmit =
        validation(this.name, "name") &&
        validation(this.city, "city") &&
        validation(this.age, "age") &&
        validation(this.phone, "phone") &&
        validation(this.email, "email");

      if (isSubmit) {
        let phone = this.phone.replace(/[^0-9]/g, "");

        this.$store.commit("updateUserData", {
          name: this.name,
          email: this.email,
          phone: phone,
          age: this.age,
          city: this.city,
          link: "https://kids.askona.ru" + this.videoLink,
        });

        this.$store.dispatch("startVerification");
        this.$store.commit("openQuiz");
      }
    },
    closePopup() {
      this.$store.commit("updateStatusPopup", {
        status: false,
        data: null,
      });

      this.$store.commit("closeQuiz");
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const format = ["video/ogg", "video/mp4", "video/quicktime", "video/webm"];

      if (file.size > 262144000) {
        console.log("Файл не должен превышать 250мб");
        return;
      }

      if (!format.includes(file.type)) {
        console.log("Не соответствует формат файла");
        return;
      }

      const chunkSize = 1024 * 1024; // размер каждого фрагмента (1 МБ)
      const hash = md5(file.name + file.size);
      this.statusVideo = "loading";

      let start = 0;
      let end = Math.min(chunkSize, file.size);

      const uploadChunk = (file, start, end) => {
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("chunk", chunk);

        // Отправка фрагмента на сервер
        axios
          .post("https://kids.askona.ru/code/upload.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Upload-Id": hash,
              "Portion-From": start,
              "Portion-Size": chunkSize,
              "File-Type": file.type,
            },
          })
          .then((res) => {
            // Если загрузка фрагмента успешна, переходим к следующему фрагменту
            start += chunkSize;
            end = Math.min(start + chunkSize, file.size);

            this.progress = (start / file.size) * 100;

            if (start < file.size) {
              uploadChunk(file, start, end);
            } else {
              // Вся загрузка завершена
              this.statusVideo = "loaded";
              this.fileName = file.name;
              this.videoLink = res.data;
              this.progress = 0;
            }
          })
          .catch((error) => {
            console.error(error);
            // Обработка ошибки при загрузке фрагмента
          });
      };

      uploadChunk(file, start, end);
    },
  },
};
</script>

<style>
.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}

.popup-form__wrapper {
  max-width: 520px !important;
  width: 100%;
}

.popup-form__title {
  display: block;
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 40px;
  width: 100%;
}

.popup-form__file {
  align-self: flex-start;
  margin-bottom: 30px;
}

.popup-form__button {
  z-index: 100;
}

.file-group {
  width: 100%;
  max-width: 520px;
}

.file-group__indicator {
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: rgba(250, 250, 250, 0.308);
  width: 95%;
  overflow: hidden;
}

.file-group__indicator::before {
  content: "";

  display: block;
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  transform-origin: 0;
  transform: scaleX(var(--loader));
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .popup-form__title {
    font-size: 32px;
  }
}
</style>
