<template>
  <h3 class="title-3">
    <slot />
  </h3>
</template>

<script>
export default {
};
</script>

<style>
.title-3 {
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  color: #383838;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .title-3 {
    font-size: 14px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .title-3 {
    font-size: 13px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .title-3 {
    font-size: 12px;
  }
}
</style>