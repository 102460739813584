<template>
  <div class="input-wrapper">
    <input
      class="input-wrapper__input"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('castomBlur')"
      :disabled="disabled"
      :placeholder="placeholder"
      v-maska:[options] />
  </div>
</template>

<script>
import { vMaska } from "maska";

export default {
  props: ["modelValue", "disabled", "placeholder"],
  emits: ["update:modelValue", "castomBlur"],
  directives: { maska: vMaska },
  data: () => ({
    options: {
      mask: "+#(###)###-####",
      postProcess: (value) => {
        if (value.length > 1) {
          return value[1] == "7"
            ? value
            : value.substring(0, 1) + "7(" + (value[1] != "8" ? value[1] : "") + value.substring(1 + value.length);
        }

        return value;
      },
    },
  }),
};
</script>

<style></style>
