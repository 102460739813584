<template>
  <p class="subtitle-1">
    <slot />
  </p>
</template>

<script>
export default {
};
</script>

<style>
.subtitle-1 {
  width: 650px;
  margin-bottom: 40px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #565656;
}

.subtitle-1 p {
  padding-bottom: 40px;
}

.subtitle-1 p:last-child {
  padding-bottom: 0;
}

.subtitle-1_quiz {
  font-weight: 500;
  line-height: 1.6;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .subtitle-1 {
    width: 560px;
    margin-bottom: 35px;
  }

  .subtitle-1 p {
    padding-bottom: 35px;
  }
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .subtitle-1 {
    width: 425px;
    margin-bottom: 35px;

    font-size: 14px;
    line-height: 1.6;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .subtitle-1 {
    width: 468px;
    margin-bottom: 30px;
  }

  .subtitle-1 p {
    padding-bottom: 30px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .subtitle-1 {
    width: 100%;
    margin-bottom: 25px;

    font-size: 12px;
  }

  .subtitle-1 p {
    padding-bottom: 25px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .subtitle-1 {
    margin-bottom: 20px;
  }

  .subtitle-1 p {
    padding-bottom: 20px;
  }
}
</style>