<template>
  <section class="step modal-content__step">
    <div class="container step__container">
      <div class="step__content">
        <template v-if="data.question">
          <H2 class="step__title" v-if="question.text_question" v-html="question.text_question" />
          <QuizLabel v-if="question.select_ans" />
          <ul class="step__list" v-if="layout">
            <ItemQuiz
              :class="selected(item)"
              v-for="item in question.answers"
              :key="item.text_answer"
              :data="item"
              @click="selection(item)" />
          </ul>
          <ul class="step__list step__list_quiz" v-else>
            <ButtonRadio
              :class="selected(item)"
              v-for="item in question.answers"
              :key="item.text_answer"
              :data="item"
              @click="selection(item)" />
          </ul>
        </template>
        <template v-else>
          <PopupConfirmation v-if="$store.state.verification == 'code'" />
          <PopupContactGood v-else-if="$store.state.verification == 'success'" />
          <FormQuiz :data="infoForm" v-else />
        </template>
      </div>
      <aside class="step__aside">
        <div class="gift-block" v-if="question.text_question && $store.state.verification != 'success'">
          <img src="@/assets/images/bear.png" alt="gift" class="gift-image" />
          <div class="gift-value">
            <p
              >Ваша<span>{{ data.step * 2 < 7 ? data.step * 2 : 7 }}%</span>скидка</p
            >
          </div>
        </div>
        <div class="step__buttons" v-if="question.text_question && $store.state.verification != 'success'">
          <ButtonPrev class="button-prev_empty" :class="data.step <= 1 ? 'disabled' : ''" @click="$emit('prevEvent')" />
          <ButtonNextText
            class="step__button-next"
            :class="!currentAnswer.size ? 'disabled' : ''"
            @click="next"
            v-if="question.text_question" />
        </div>
      </aside>
    </div>
  </section>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import QuizLabel from "@/components/element/QuizLabel.vue";
import ItemQuiz from "@/components/block/item/ItemQuiz.vue";
import ButtonRadio from "@/components/block/item/ItemQuizRadio.vue";
import ButtonPrev from "@/components/element/ButtonPrev.vue";
import ButtonNextText from "@/components/element/ButtonNextText.vue";
import FormQuiz from "@/components/block/FormQuiz.vue";
import PopupConfirmation from "@/components/section/quiz/PopupConfirmation.vue";
import PopupContactGood from "@/components/section/quiz/PopupContactGood.vue";

export default {
  name: "StepApp",
  props: {
    data: {
      require: true,
      default: () => ({}),
    },
    infoForm: {
      require: true,
      default: () => ({}),
    },
    sale: {
      require: true,
    },
  },
  emits: ["nextEvent"],
  components: {
    ItemQuiz,
    H2,
    QuizLabel,
    ButtonPrev,
    ButtonNextText,
    ButtonRadio,
    FormQuiz,
    PopupConfirmation,
    PopupContactGood,
  },
  data() {
    return {
      currentItem: this.data,
      currentAnswer: this.data.answer,
    };
  },
  computed: {
    question() {
      return this.data.question || {};
    },
    layout() {
      return this.question.answers?.[0].img_answer;
    },
    selected() {
      return (item) => (this.currentAnswer.has(item) ? "active" : "");
    },
  },
  methods: {
    selection(item) {
      if (this.question.select_ans) {
        if (this.currentAnswer.has(item)) {
          this.currentAnswer.delete(item);
          return;
        }

        this.currentAnswer.add(item);
        return;
      }

      this.currentAnswer.clear();
      this.currentAnswer.add(item);
      this.next();
    },
    next() {
      if (this.currentAnswer.size != 0) {
        let finishAnswer = Object.assign({}, this.data);
        finishAnswer.answer = this.currentAnswer;

        // Отправка ответа в GTM
        let dataGtm = this.question.text_question;

        this.currentAnswer.forEach((value) => {
          dataGtm += ", " + value.text_answer;
        });

        window.dataLayer.push({
          event: "UAevent",
          eventTestChoice: dataGtm,
        });

        this.$emit("nextEvent", finishAnswer);
      }
    },
  },
};
</script>

<style>
.step {
  width: 100%;
  padding: 50px 0;
}

.step__container {
  padding: 0 !important;
}

.step__title {
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 40px;
  margin-bottom: 60px;
  color: #ffffff;
}

.step__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 50px !important;
}

.step__list_quiz {
  grid-template-columns: repeat(2, 1fr);
}

.step__list_form {
  grid-template-columns: repeat(1, 1fr);
}

.step__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step__button-next {
  margin-left: 50px;
}

.form-quiz + .step__aside {
  display: none;
}

@media (max-width: 1024.98px) {
  .step {
    padding: 30px 0;
  }

  .step__title {
    font-size: 32px;
    margin-bottom: 50px;
  }

  .step__list {
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 10px !important;
  }

  .step__list_quiz {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
