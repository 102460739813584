<template>
  <section class="main-popular swiper-slide">
    <div class="section-content">
      <h2 class="main-popular__title section-title" v-html="content.block_product.title"></h2>
      <div class="popular-list">
        <div class="popular-list__item">
          <a class="popular-list__link" :class="{ active: productStatus == 'untilthree' }" @click="productStatus = 'untilthree'">0 - 3</a>
        </div>
        <div class="popular-list__item">
          <a class="popular-list__link" :class="{ active: productStatus == 'threeseven' }" @click="productStatus = 'threeseven'">3 - 7</a>
        </div>
        <div class="popular-list__item">
          <a class="popular-list__link" :class="{ active: productStatus == 'beforeseven' }" @click="productStatus = 'beforeseven'">7 +</a>
        </div>
      </div>
      <div class="main-popular__content" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <div class="popular-slider swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in getProduct" :key="index" ref="product">
              <div class="item-catalog-product__image-wrapper">
                <div class="popular-slider__discount" v-if="item.discount">{{ item.discount }}</div>
                <div class="product__image">
                  <div class="product__switch image-switch">
                    <div class="image-switch__item" v-for="item in item.img_product" :key="item">
                      <div class="image-switch__img"><img :src="item.sizes.product" alt="" /></div>
                    </div>
                  </div>
                  <ul class="product__image-pagination image-pagination" aria-hidden="true"></ul>
                </div>
              </div>
              <div class="descr-block">
                <div class="slide-title">{{ item.name_product }}</div>
                <div class="slide-descr"
                  ><p>{{ item.text_product }}</p></div
                >
                <div class="slide-btns">
                  <a :href="item.link_product" class="more-link" target="_blank"><i class="icon icon-btn_arrow"></i></a>
                  <span class="old-price">{{ item.Price.prais_product }}</span>
                  <span class="price">{{ item.Price.prais_sale_product }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-row" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <a class="main-popular__btn btn-style" @click="openQuiz">Подобрать товар</a>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import Swiper from "swiper";

export default {
  name: "Slide_4",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
      productStatus: "threeseven",
    };
  },
  computed: {
    getProduct() {
      return this.content.block_product.product.filter((item) => item.ages === this.productStatus);
    },
  },
  methods: {
    ...mapMutations(["openQuiz"]),
  },
  mounted() {
    document.querySelectorAll(".main-popular .popular-slider").forEach(function (popularSlider) {
      new Swiper(popularSlider, {
        speed: 800,
        loop: false,
        slidesPerView: "auto",
        spaceBetween: 0,
      });
    });

    let currentProduct = this.$refs.product;

    currentProduct.forEach((item) => {
      const imageSwitchItems = item.querySelectorAll(".image-switch__item");
      const imagePagination = item.querySelector(".image-pagination");

      if (imageSwitchItems.length > 1) {
        imageSwitchItems.forEach((el, index) => {
          el.setAttribute("data-index", index);
          imagePagination.innerHTML += `<li class="item-catalog-product__bullet ${
            index == 0 ? "item-catalog-product__bullet_active" : ""
          }" data-index="${index}"></li>`;

          el.addEventListener("mouseenter", (e) => {
            item.querySelectorAll(".item-catalog-product__bullet").forEach((el) => {
              el.classList.remove("item-catalog-product__bullet_active");
            });
            item
              .querySelector(`.item-catalog-product__bullet[data-index="${e.currentTarget.dataset.index}"]`)
              .classList.add("item-catalog-product__bullet_active");
          });

          el.addEventListener("mouseleave", () => {
            item.querySelectorAll(".item-catalog-product__bullet").forEach((el) => {
              el.classList.remove("item-catalog-product__bullet_active");
            });
            item.querySelector(`.item-catalog-product__bullet[data-index="0"]`).classList.add("item-catalog-product__bullet_active");
          });
        });
      }
    });
  },
};
</script>

<style>
.item-catalog-product {
  display: flex;
  flex-direction: column;
  min-height: 640px;
  height: auto;

  padding-left: 25px;
  padding-right: 25px;
  margin-top: 50px;
}

.item-catalog-product:hover .item-catalog-product__wrapper {
  box-shadow: 0px 0px 50px 0px rgba(82, 82, 82, 0.2);
}

.item-catalog-product__wrapper {
  overflow: hidden;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  transition: box-shadow 0.5s;
}

.item-catalog-product__content-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.item-catalog-product__image-wrapper {
  position: relative;

  width: 100%;
  padding: 3px 3px 0 3px;
  margin-bottom: 24px;
}

.item-catalog-product__image {
  transition: 0.5s;
}

.item-catalog-product:hover .item-catalog-product__image {
  transform: scale(1.05);
}

.item-catalog-product__pagination {
  position: relative;
  top: -20px;
  margin-bottom: -20px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.item-catalog-product__bullet {
  width: 20px;
  height: 4px;
  margin: 3px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0 #0000003f;

  background-color: #ffffff;
}

.item-catalog-product__bullet_active {
  width: 20px;
  height: 4px;

  background-color: #00a7b5;
}

.item-catalog-product__switching-area-wrapper {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  width: 100%;
  height: 100%;
}

.item-catalog-product__switching-area-js {
  flex-grow: 1;
}

.item-catalog-product__content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.item-catalog-product__title {
  margin-bottom: 15px;
}

.item-catalog-product__text {
  margin-bottom: 15px;
}

.item-catalog-product__price {
  margin-top: auto;
  margin-bottom: 20px;
}

.item-catalog-product__button {
  border-radius: 50px;

  opacity: 0;

  pointer-events: none;

  transition: opacity 0.5s;
}

.item-catalog-product:hover .item-catalog-product__button {
  opacity: 1;

  pointer-events: all;
}

.product__image {
  position: relative;
  overflow: hidden;
  display: block;
  height: 251px;
  border-radius: 10px;
}

.image-switch {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
}

.image-switch__item {
  flex-grow: 1;
  cursor: pointer;
}

.image-switch__img {
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  pointer-events: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.image-switch__img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-switch__item:first-child .image-switch__img {
  opacity: 1;
  z-index: -1;
}

.image-switch__item:hover .image-switch__img {
  opacity: 1;
  z-index: -1;
}

.image-pagination {
  position: absolute;
  z-index: 30;
  left: 0;
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popular-slider__discount {
  position: absolute;
  z-index: 100;
  top: 10px;
  left: 10px;
  font-family: Exo 2;
  display: inline-flex;
  padding: 4px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: #09d3c6;
  box-shadow: 0px 8px 15px 0px #035256;
  color: #ffffff;
  font-weight: 700;
}

@media (max-width: 1440.98px) {
  .product__image {
    height: 200px;
  }

  .item-catalog-product__image-wrapper {
    margin-bottom: 5px;
  }

  .popular-slider__discount {
    font-size: 14px;
  }
}
</style>
