<template>
  <div class="popup" @click.self="closePopup">
    <div class="popup__wrapper">
      <ButtonClose class="popup__close mfp-close__black" @click="closePopup" />
      <H3 class="popup__title" v-html="popup.data.title" />
      <a
        class="popup__link"
        :href="item.link"
        v-for="item in popup.data.list"
        :key="item.text_link"
        target="_blank"
        v-html="item.text_link"></a>
      <div id="video_modal1" class="modal-video-style mfp-hide">
        <div class="modal-content">
          <a href="javascript:void(0);" class="video-link js-video-start"
            ><span><i class="icon icon-play"></i></span
          ></a>
          <iframe
            class="video-file"
            data-src="https://www.youtube.com/embed/gWwti1JmhsY"
            title="YouTube"
            frameborder="0"
            allowfullscreen></iframe>
          <div class="modal-descr">
            <div class="image-block">
              <img src="images/video_preview.jpg" alt="video" class="video-preview" />
            </div>
            <div class="descr-block">
              <div class="modal-title">Марк и самокат</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import H3 from "@/components/element/H3App.vue";
import ButtonClose from "@/components/element/ButtonClose.vue";
import { mapState } from "vuex";

export default {
  name: "PopupApp",
  components: {
    H3,
    ButtonClose,
  },
  computed: mapState({
    popup: "popup",
  }),
  methods: {
    closePopup() {
      this.$store.commit("updateStatusPopup", {
        status: false,
        data: null,
      });
    },
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.4);
}

.popup__wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 60px 50px;

  background-color: #ffffff;
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.popup__title {
  margin-bottom: 20px;

  font-family: Lineatura, sans-serif;
  font-weight: 700;
  line-height: 130%;
  color: #101010;
}

.popup__link {
  margin-bottom: 10px;

  display: inline-block;
  font-weight: 400;
  color: #666;
  text-decoration: underline !important;
  transition: color 0.3s;
  overflow-wrap: break-word;
}

.popup__link:hover {
  text-decoration: none !important;
}

.popup__link:last-child {
  margin-bottom: 0;
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .popup__wrapper {
    width: 295px;
    padding: 40px 15px;
  }

  .popup__close {
    top: 10px;
    right: 10px;
  }

  .popup__title {
    margin-bottom: 20px;

    font-size: 14px;
  }
}
</style>
