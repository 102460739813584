<template>
  <div class="quiz-label"> Выберите один или несколько </div>
</template>

<script>
// export default {
//   props: ["parentClass"],
//   data() {
//     return {
//       classObject: {
//         button: true,
//         [this.parentClass]: this.parentClass,
//       },
//     };
//   },
// };
</script>

<style>
.quiz-label {
  display: flex;
  align-items: center;
  width: fit-content;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  margin-top: 0px;
  margin-bottom: 30px;
  border-radius: 4px;

  background-color: rgba(0, 167, 181, 0.3);
  box-shadow: rgba(0, 0, 0, 0.25);

  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

.quiz-label:before {
  content: "";

  width: 16px;
  height: 16px;
  /* margin-top: -3px; */
  margin-right: 10px;

  background-image: url("@/assets/images/quiz-label-chose.svg");
  background-size: contain;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .quiz-label {
    margin-top: -30px;
    margin-bottom: 40px;
  }
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .quiz-label {
    margin-top: -25px;
    margin-bottom: 35px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .quiz-label {
    margin-top: -20px;
    margin-bottom: 30px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .quiz-label {
    margin-top: -15px;
    margin-bottom: 25px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .quiz-label {
    padding-top: 4px;
    padding-right: 8px;
    padding-bottom: 6px;
    padding-left: 8px;

    font-size: 11px;
    margin-top: -14px;
    margin-bottom: 20px;
  }

  .quiz-label:before {
    width: 12px;
    height: 12px;
    margin-top: -3px;
    margin-right: 5px;
  }
}
</style>
