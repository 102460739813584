import { createStore } from "vuex";
import axios from "axios";
import { makeXHR, getCookie } from "@/helpers/index";

export default createStore({
  state() {
    return {
      apiData: false,
      preloader: true,
      loading: false,
      data: null,
      discount: null,
      dataQuiz: null,
      statusQuiz: false,
      linkQuiz: null,
      quiz: {
        currentQuestion: 0,
        currentStep: 0,
        step: [],
      },
      currentSale: 1,
      userData: {},
      verification: null,
      errorVerification: new Set(),
      eventCode: null,
      code: null,
      timeVerification: 60,
      popup: {
        status: false,
        data: null,
      },
      statusStart: true,
      statusEnd: true,
    };
  },
  mutations: {
    updateStatusApi(state) {
      state.apiData = true;
    },
    preloaderOff(state) {
      state.preloader = false;
    },
    updateData(state, preload) {
      state.data = preload;
    },
    updateDiscount(state, preload) {
      state.discount = preload;
    },
    updateLoading(state) {
      state.loading = !state.loading;
    },
    updateDataQuiz(state, preload) {
      state.dataQuiz = preload;
    },
    openQuiz(state) {
      state.statusQuiz = true;
    },
    closeQuiz(state) {
      state.statusQuiz = false;
      state.quiz = {
        currentQuestion: 0,
        currentStep: 0,
        step: [],
      };
      state.currentSale = 1;
      state.userData = {};
      state.verification = null;
      state.errorVerification = new Set();
      state.eventCode = null;
      state.code = null;
    },
    updateLinkQuiz(state, preload) {
      state.linkQuiz = preload;
    },
    updateStatusQuiz(state, preload) {
      state.quiz = preload;
    },
    updateUserData(state, preload) {
      state.userData = preload;
    },
    updateEventCode(state, preload) {
      state.eventCode = preload;
    },
    updateCode(state, preload) {
      state.code = preload;
    },
    updateVerification(state, preload) {
      state.verification = preload;
    },
    updateErrorVerification(state, preload) {
      state.errorVerification.add(preload);
    },
    clearErrorVerification(state) {
      state.errorVerification.clear();
    },
    startTime(state) {
      state.timeVerification = 60;
    },
    updateTime(state) {
      state.timeVerification -= 1;
    },
    updateStatusPopup(state, preload) {
      state.popup = preload;
    },
    updateAddSale(state) {
      state.currentSale = state.currentSale + 1;
    },
    updateDelSale(state) {
      state.currentSale = state.currentSale - 1;
    },
    updateStatusStart(state, preload) {
      state.statusStart = preload;
    },
    updateStatusEnd(state, preload) {
      state.statusEnd = preload;
    },
  },
  actions: {
    async loadingData(context) {
      const [contentResponse, quizResponse, mediaResponse] = await Promise.all([
        axios.get(`${window.apiSt}/wp-json/wp/v2/pages/8`),
        axios.get(`${window.apiSt}/wp-json/wp/v2/pages/68`),
        axios.get(`${window.apiSt}/wp-json/wp/v2/media?per_page=100`),
      ]);

      let transformMedia = {};
      mediaResponse.data.forEach((item) => (transformMedia[item.id] = item));

      let transformQuestions = {};
      quizResponse.data.acf.main_questions?.question?.forEach((item) => (transformQuestions[item.nomber_question] = item));

      context.commit("updateData", {
        contentResponse: contentResponse.data.acf,
        mediaResponse: transformMedia,
      });

      context.commit("updateDataQuiz", {
        quizResponse: quizResponse.data.acf,
        quizQuestions: transformQuestions,
      });

      context.commit("updateStatusApi");

      setTimeout(() => {
        context.commit("preloaderOff");
      }, 1000);
    },
    async startVerification(context) {
      context.commit("clearErrorVerification");
      context.commit("updateStatusStart", false);

      // Данные Аскона
      const dataVerification = new FormData();
      dataVerification.append("phone", context.state.userData.phone);

      // Данные отправки CRM
      const crmDataVerification = new FormData();

      crmDataVerification.append("name", context.state.userData.name);
      crmDataVerification.append("email", context.state.userData.email);
      crmDataVerification.append("phone", context.state.userData.phone);
      crmDataVerification.append("city", context.state.userData.city);
      crmDataVerification.append("age", context.state.userData.age);
      crmDataVerification.append("video_url", context.state.userData.link);
      crmDataVerification.append("code", "null");

      context.state.quiz.step.forEach((item, index) => {
        let answerArray = "";

        item.answer.forEach((value) => {
          answerArray = answerArray + value.text_answer + ", ";
        });

        crmDataVerification.append("answer" + index, answerArray);
      });

      let arrayUrl = [];
      let link = context.state.linkQuiz || [];

      Object.values(link).forEach((item) => {
        if (item !== undefined) {
          arrayUrl.push(item);
        }
      });

      crmDataVerification.append("timezone", Math.floor((new Date().getTimezoneOffset() / 60) * -1));
      crmDataVerification.append("redirect_url", arrayUrl.join(", "));
      crmDataVerification.append("referrer", document.referrer);
      crmDataVerification.append("ct_session_id", getCookie("_ct_client_global_id") || "");

      const utms = {
        utm_campaign: "",
        utm_source: "",
        utm_medium: "",
        utm_content: "",
        utm_term: "",
      };

      if (location.search.length > 0) {
        const query = new URLSearchParams(location.search);
        const iter = query.entries();

        let entry = iter.next();
        const utmKeys = Object.keys(utms);

        while (entry) {
          if (entry.done) {
            break;
          }

          const [k, v] = entry.value;

          if (k && utmKeys.includes(k.toLowerCase())) {
            utms[k] = v;
          }

          entry = iter.next();
        }
      }

      Object.keys(utms).forEach((k) => crmDataVerification.append(k, utms[k]));

      let objErrors = {
        send_code_failed: "Не удалось отправить код подтверждения повторите попытку позже",
        limit_exceeded: "Превышен лимит попыток отправки кода подтверждения",
        incorrect_query: "Поле телефон не заполнено или не корректно заполненно",
      };

      makeXHR("/code/sendCode.php", dataVerification, (xhr) => {
        let result = null;

        try {
          result = JSON.parse(xhr.response);
          context.commit("updateStatusStart", true);
        } catch (e) {
          console.log(e);

          crmDataVerification.append("creatio_response", "askona-error");
          crmDataVerification.append("verified", false);
          context.commit("updateStatusStart", true);

          makeXHR("/code/compilation.php", crmDataVerification, (xhr) => {
            try {
              JSON.parse(xhr.response);
            } catch (e) {
              console.log(e);
            }
          });
        }

        if (!result) {
          context.commit("updateErrorVerification", "Произошла неизвестная ошибка, обратитесь к администратору сайта");
        } else {
          if (result.success) {
            if (result.data.next_action == "check_code") {
              context.commit("updateVerification", "code");
              context.commit("startTime");
              context.commit("updateStatusPopup", {
                status: false,
                data: null,
              });
            }
          } else {
            if (result.errors.length) {
              let nameErrors = "";

              result.errors.forEach((item, index) => {
                if (Object.prototype.hasOwnProperty.call(objErrors, item.code)) {
                  context.commit("updateErrorVerification", objErrors[item.code]);

                  if (index === 0) {
                    nameErrors = item.code;
                  } else {
                    nameErrors += ", " + item.code;
                  }
                }
              });

              crmDataVerification.append("creatio_response", nameErrors);
              crmDataVerification.append("verified", false);

              makeXHR("/code/compilation.php", crmDataVerification, (xhr) => {
                try {
                  JSON.parse(xhr.response);
                } catch (e) {
                  console.log(e);
                }
              });
            }
          }
        }
      });
    },
    async endVerification(context) {
      let startLoading = Date.now();

      context.commit("clearErrorVerification");
      context.commit("updateStatusEnd", false);
      context.commit("updateLoading");

      const dataVerification = new FormData();

      dataVerification.append("name", context.state.userData.name);
      dataVerification.append("email", context.state.userData.email);
      dataVerification.append("phone", context.state.userData.phone);
      dataVerification.append("code", context.state.code);

      // Данные отправки РК
      const crmDataVerification = new FormData();

      crmDataVerification.append("name", context.state.userData.name);
      crmDataVerification.append("email", context.state.userData.email);
      crmDataVerification.append("phone", context.state.userData.phone);
      crmDataVerification.append("city", context.state.userData.city);
      crmDataVerification.append("age", context.state.userData.age);
      crmDataVerification.append("video_url", context.state.userData.link);
      crmDataVerification.append("code", context.state.code);

      context.state.quiz.step.forEach((item, index) => {
        let answerArray = "";

        item.answer.forEach((value) => {
          answerArray = answerArray + value.text_answer + ", ";
        });

        crmDataVerification.append("answer" + index, answerArray);
      });

      let arrayUrl = [];
      let link = context.state.linkQuiz || [];

      Object.values(link).forEach((item) => {
        if (item !== undefined) {
          arrayUrl.push(item);
        }
      });

      crmDataVerification.append("timezone", Math.floor((new Date().getTimezoneOffset() / 60) * -1));
      crmDataVerification.append("redirect_url", arrayUrl.join(", "));
      crmDataVerification.append("referrer", document.referrer);
      crmDataVerification.append("ct_session_id", getCookie("_ct_client_global_id") || "");

      const utms = {
        utm_campaign: "",
        utm_source: "",
        utm_medium: "",
        utm_content: "",
        utm_term: "",
      };

      if (location.search.length > 0) {
        const query = new URLSearchParams(location.search);
        const iter = query.entries();

        let entry = iter.next();
        const utmKeys = Object.keys(utms);

        while (entry) {
          if (entry.done) {
            break;
          }

          const [k, v] = entry.value;

          if (k && utmKeys.includes(k.toLowerCase())) {
            utms[k] = v;
          }

          entry = iter.next();
        }
      }

      Object.keys(utms).forEach((k) => crmDataVerification.append(k, utms[k]));

      let objErrors = {
        wrong_check_code: "Введен неправильный код из смс",
        limit_exceeded: "Превышен лимит попыток ввода кода",
        incorrect_query: "Не заполнены обязательные поля",
        contact_create_failed: "Не удалось зарегистрировать пользователя в BPM, повторите попытку позже",
        different_users: "Номер телефона и email принадлежат разным пользователям",
        action_already_applied: "Вы уже зарегистрированы в данной рекламной компании",
      };

      makeXHR("/code/confirmCode.php", dataVerification, (xhr) => {
        let result = null;

        try {
          result = JSON.parse(xhr.response);
          context.commit("updateStatusEnd", true);

          let endLoading = Date.now() - startLoading > 3000 ? 3000 - (Date.now() - startLoading) : 0;

          setTimeout(() => {
            context.commit("updateLoading");
          }, endLoading);
        } catch (e) {
          console.log(e);

          crmDataVerification.append("creatio_response", "askona-error");
          crmDataVerification.append("verified", false);
          context.commit("updateStatusEnd", true);

          makeXHR("/code/compilation.php", crmDataVerification, (xhr) => {
            try {
              JSON.parse(xhr.response);
            } catch (e) {
              console.log(e);
            }
          });
        }

        if (!result) {
          context.commit("updateErrorVerification", "Произошла неизвестная ошибка, обратитесь к администратору сайта");
        } else {
          if (result.success) {
            if (result.data.next_action == "success") {
              crmDataVerification.append("creatio_response", "success");
              crmDataVerification.append("verified", true);
            }

            makeXHR("/code/compilation.php", crmDataVerification, (xhr) => {
              try {
                JSON.parse(xhr.response);
              } catch (e) {
                console.log(e);
              }
            });

            context.commit("updateVerification", "success");
          } else {
            if (result.errors.length) {
              let nameErrors = "";

              result.errors.forEach((item, index) => {
                if (Object.prototype.hasOwnProperty.call(objErrors, item.code)) {
                  context.commit("updateErrorVerification", objErrors[item.code]);

                  if (index === 0) {
                    nameErrors = item.code;
                  } else {
                    nameErrors += ", " + item.code;
                  }
                }
              });

              crmDataVerification.append("creatio_response", nameErrors);
              crmDataVerification.append("verified", false);

              makeXHR("/code/compilation.php", crmDataVerification, (xhr) => {
                try {
                  JSON.parse(xhr.response);
                } catch (e) {
                  console.log(e);
                }
              });
            }
          }
        }
      });
    },
  },
});
