<template>
  <li class="item-quiz" tabindex="0" :class="{ 'item-quiz__no-img': !data.img_answer }">
    <img class="item-quiz__image" :src="data.img_answer" v-if="data.img_answer" />
    <Subtitle1 class="item-quiz__text subtitle-1_quiz" v-html="data.text_answer"></Subtitle1>
  </li>
</template>

<script>
import Subtitle1 from "@/components/element/Subtitle1App.vue";
export default {
  name: "ItemQuiz",
  props: {
    data: {
      require: true,
      default: () => ({}),
    },
  },
  components: {
    Subtitle1,
  },
};
</script>

<style>
.item-quiz {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #fff;

  transition: box-shadow 0.3s;
}

.item-quiz__no-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-quiz:hover {
  box-shadow: 0px 0px 25px rgba(82, 82, 82, 0.25);

  cursor: pointer;
}

.item-quiz:hover .item-quiz__text,
.item-quiz.active .item-quiz__text {
  color: #00a7b5;
}

.item-quiz.active {
  border: 1px solid #00a7b5;

  color: #00a7b5;
}

.item-quiz__image {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 10px;

  overflow: hidden;

  background-size: cover;
}

.item-quiz__text {
  width: auto;
  padding: 0 10px;
  font-size: 16px;
  font-family: Exo2, sans-serif;
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.item-quiz__no-img .item-quiz__text {
  padding: 20px 0;
  margin-bottom: 0px;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
}
</style>
