<template>
  <div class="quiz-popup modal-quiz-style" ref="quiz" data-step="1">
    <div class="quiz-popup__wrapper">
      <div class="modal-content quiz-popup__modal-content">
        <ButtonClose @click="$store.commit('closeQuiz')" />
        <PopupStart v-if="statusQuiz.currentStep == 0 && !$store.state.verification" :data="dataQuiz.first_screen" @start="stepStart" />
        <PopupStep
          v-if="statusQuiz.currentStep > 0 || $store.state.verification"
          :data="сurrentStep"
          @next-event="stepNext"
          @prev-event="stepPrev"
          :key="сurrentStep.step"
          :info-form="dataQuiz.submission_form"
          :sale="dataQuiz.first_screen.sale" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonClose from "@/components/element/ButtonClose.vue";
import PopupStart from "@/components/section/quiz/PopupStart.vue";
import PopupStep from "@/components/section/quiz/PopupStep.vue";
import { mapState } from "vuex";

export default {
  name: "QuizPopup",
  components: {
    ButtonClose,
    PopupStart,
    PopupStep,
  },
  data() {
    return {
      dataQuiz: this.$store.state.dataQuiz.quizResponse,
      dataQuestions: this.$store.state.dataQuiz.quizQuestions,
    };
  },
  computed: {
    ...mapState({
      statusQuiz: "quiz",
    }),
    сurrentStep() {
      let itemAnswer = this.statusQuiz.step.find((item) => item.step == this.statusQuiz.currentStep);

      if (!itemAnswer) {
        itemAnswer = {
          step: this.statusQuiz.currentStep,
          question: this.dataQuestions[this.statusQuiz.currentQuestion],
          sale: 0,
          answer: new Set(),
        };
      }

      return itemAnswer;
    },
  },
  methods: {
    stepStart() {
      this.$store.commit("updateStatusQuiz", {
        currentQuestion: 1,
        currentStep: 1,
        step: [],
      });
    },
    stepNext(item) {
      this.$refs.quiz.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      let answers = this.statusQuiz.step.slice();
      answers.push(item);

      let nextQuestion = null;
      let сounter = 0;

      item.answer.forEach((value) => {
        if (сounter == 0) {
          nextQuestion = value.next_question;
        }

        if (value.link_site) {
          this.$store.commit("updateLinkQuiz", { mattresses: value.link_site_2, ergomotion: value.link_site });
        }

        сounter++;
      });

      this.$store.commit("updateStatusQuiz", {
        currentQuestion: nextQuestion,
        currentStep: this.statusQuiz.currentStep + 1,
        step: answers,
      });

      this.$store.commit("updateAddSale");
    },
    stepPrev() {
      if (this.statusQuiz.currentStep <= 1) return;
      let stepList = this.statusQuiz.step.slice();
      let hasStep = stepList.find((item) => item.step == this.statusQuiz.currentStep);

      if (hasStep) stepList.pop();

      let prevStep = this.statusQuiz.currentStep - 1;
      let prevQuestion = this.statusQuiz.step.find((item) => item.step == prevStep).question;

      this.$store.commit("updateStatusQuiz", {
        currentQuestion: prevQuestion,
        currentStep: this.statusQuiz.currentStep - 1,
        step: stepList,
      });

      this.$store.commit("updateDelSale");
    },
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "visible";
  },
};
</script>

<style>
.quiz-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  width: 100%;
  height: 100%;

  background-color: #ffffff;
  padding: 0 !important;
}

.quiz-popup__wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
  padding: 0 15px;
}

.quiz-popup__modal-content {
  justify-content: flex-start !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
