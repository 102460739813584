<template>
  <div class="popup-video modal-video-style" @click.self="closePopup">
    <div class="popup-video__wrapper modal-content">
      <ButtonClose class="popup__close popup-video__close" @click="closePopup" />
      <video class="popup-video__file" controls playsinline preload="metadata">
        <source :src="popup.data.link" />
      </video>
    </div>
  </div>
</template>

<script>
import ButtonClose from "@/components/element/ButtonClose.vue";
import { mapState } from "vuex";

export default {
  name: "PopupApp",
  components: {
    ButtonClose,
  },
  computed: mapState({
    popup: "popup",
  }),
  data() {
    return {
      status: true,
      type: false,
    };
  },
  methods: {
    closePopup() {
      this.$store.commit("updateStatusPopup", {
        status: false,
        data: null,
      });
    },
  },
};
</script>

<style>
.popup-video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  filter: blur(30);
}

.popup-video__wrapper {
  position: relative;
  border-radius: 10px;

  height: 60vmin;

  overflow: hidden;
  background-color: #000;
}

.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}

.popup-video__close {
  left: 20px !important;
}

.popup-video__file {
  height: 80vmin;
  max-width: 100%;
  object-fit: contain;
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .popup-video {
    background-color: rgba(0, 0, 0, 1);
  }

  .popup-video__wrapper {
    height: 60vmax;
  }

  .popup-video__file {
    height: 80vmax;
    object-fit: contain;
  }
}
</style>
