<template>
  <form class="form-quiz" action="" @submit.prevent>
    <H2 class="form-quiz__title" v-html="data.title" />
    <Subtitle1 class="form-quiz__text subtitle-1_quiz modal-title" v-html="data.subtitle" />
    <Input
      class="form-quiz__input"
      :class="errors.name ? 'error' : ''"
      v-model="name"
      @castom-blur="validation(name, 'name')"
      :disabled="$store.state.userData.name"
      placeholder="Ваше имя* "></Input>
    <Input
      class="form-quiz__input"
      :class="errors.email ? 'error' : ''"
      v-model="email"
      @castom-blur="validation(email, 'email')"
      placeholder="E-mail*"></Input>
    <InputPhone
      class="form-quiz__input"
      :class="errors.phone ? 'error' : ''"
      v-model="phone"
      @castom-blur="validation(phone, 'phone')"
      :disabled="$store.state.userData.phone"
      placeholder="Телефон*"></InputPhone>
    <Button class="form-quiz__button" @click="submitButton">{{ data.text_btn }}</Button>
    <div class="form-quiz__checkbox-wrapper">
      <input class="form-quiz__checkbox-input" tabindex="-1" type="checkbox" id="scales" name="scales" checked v-model="politics" />
      <div class="form-quiz__custom-input" tabindex="0"></div>
      <label class="form-quiz__checkbox-label" for="scales" v-html="data.consent_text"></label>
    </div>
    <div class="form-quiz__checkbox-wrapper" style="margin-bottom: 80px">
      <input class="form-quiz__checkbox-input" tabindex="-1" type="checkbox" id="scales2" name="scales" checked v-model="politics2" />
      <div class="form-quiz__custom-input form-quiz__custom-input2" tabindex="0"></div>
      <label class="form-quiz__checkbox-label" for="scales2" v-html="textPolitic"></label>
    </div>
    <Alert />
  </form>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Subtitle1 from "@/components/element/Subtitle1App.vue";
import Button from "@/components/element/ButtonApp.vue";
import Input from "@/components/element/InputApp.vue";
import InputPhone from "@/components/element/InputPhone.vue";
import Alert from "@/components/block/AlertApp.vue";
import { validation } from "@/helpers/index.js";
export default {
  props: {
    data: {
      require: true,
      default: () => ({}),
    },
  },
  components: {
    H2,
    Subtitle1,
    Button,
    Input,
    InputPhone,
    Alert,
  },
  data() {
    return {
      name: this.$store.state.userData.name || "",
      phone: this.$store.state.userData.phone || "",
      email: this.$store.state.userData.email || "",
      politics: true,
      politics2: true,
      errors: {
        name: false,
        phone: false,
        email: false,
      },
      verification: this.$store.state.dataQuiz.quizResponse.verification,
    };
  },
  computed: {
    textPolitic() {
      return '<a href="' + this.verification.consent_file.url + '" target="_blank">' + this.verification.consent + "</a>";
    },
  },
  methods: {
    validation(value, type = null) {
      this.errors[type] = !validation(value, type);
    },
    submitButton() {
      let isSubmit = validation(this.name, "name") && validation(this.phone, "phone") && validation(this.email, "email") && this.politics;

      if (isSubmit && this.$store.state.statusStart) {
        let phone = this.phone.replace(/[^0-9]/g, "");

        this.$store.commit("updateUserData", {
          name: this.name,
          email: this.email,
          phone: phone,
        });

        this.$store.dispatch("startVerification");
      }
    },
  },
};
</script>
<style>
.form-quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-quiz__title {
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 0px !important;
}

.form-quiz__title ::v-deep(span) {
  color: #58dce3;
}

.form-quiz__text {
  width: 100%;
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  margin-top: 20px;
  margin-bottom: 50px;
  color: #ffffff;
  text-align: center;
}

.form-quiz__button {
  width: 250px;
  margin-top: 30px;
  margin-bottom: 60px;
}

.form-quiz__checkbox-wrapper {
  position: relative;

  display: flex;
  max-width: 460px;
  width: 100%;
  margin-bottom: 30px;
}

.form-quiz__checkbox-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;

  width: 20px;
  height: 20px;
  margin: 0;
  margin-top: 3px;

  opacity: 0;
}

.form-quiz__checkbox-input:checked ~ .form-quiz__custom-input {
  background-color: #00a7b5;
}

.form-quiz__custom-input {
  position: relative;
  z-index: 10;

  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 15px;
  margin-top: 3px;
  border-radius: 4px;
  border: 1px solid #00a7b5;
}

.form-quiz__custom-input2 {
  margin-top: 1px;
}

.form-quiz__checkbox-input:checked ~ .form-quiz__custom-input:after {
  content: "";

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: url("@/assets/images/custom-checkbox.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.form-quiz__checkbox-label {
  font-family: Exo2, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
}

.form-quiz__checkbox-link {
  color: #ffffff;
}

@media (max-width: 1440.98px) {
  .form-quiz__title {
    font-size: 36px;
    line-height: 130%;
  }

  .form-quiz__text {
    font-size: 18px;
  }
}

@media (max-width: 768.98px) {
  .form-quiz__button {
    width: 250px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .form-quiz__checkbox-wrapper {
    margin-bottom: 20px !important;
  }
}
</style>
