<template>
  <section class="main-fathers swiper-slide">
    <div class="section-content">
      <h2
        class="main-fathers__title section-title"
        data-swiper-parallax="-50"
        data-swiper-parallax-duration="2000"
        v-html="content['slide-show-title']"></h2>
      <div class="main-fathers__content">
        <div id="fathers-slider" class="fathers-slider swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in content['slide-show']" :key="index">
              <div class="image-block">
                <div class="image-wrapper mobile-image">
                  <img :src="item['slide-show-img'].sizes.slide5_mobile_image" alt="type" class="slide-image lazyload" />
                </div>
                <div class="image-wrapper pad-image">
                  <img :src="item['slide-show-img'].sizes.slide5_pad_image" alt="type" class="slide-image lazyload" />
                </div>
                <div class="image-wrapper bpad-image">
                  <img :src="item['slide-show-img'].sizes.slide5_bpad_image" alt="type" class="slide-image lazyload" />
                </div>
                <div class="image-wrapper pc-image">
                  <img :src="item['slide-show-img'].sizes.slide5_pc_image" alt="type" class="slide-image lazyload" />
                </div>
              </div>
              <div class="descr-block" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
                <div class="slide-title" v-html="item['slide-show-desc']"></div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Pagination } from "swiper";
export default {
  name: "Slide_5",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  mounted() {
    new Swiper("#fathers-slider", {
      modules: [Pagination],
      speed: 800,
      spaceBetween: 0,
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        421: {
          spaceBetween: 0,
          centeredSlides: true,
        },
        768: {
          spaceBetween: 0,
          centeredSlides: false,
        },
        1024: {
          spaceBetween: 0,
          centeredSlides: false,
        },
      },
    });
  },
};
</script>

<style></style>
