<template>
  <main class="main-page swiper-slide">
    <div class="header">
      <div class="header__content">
        <a href="https://www.askona.ru/detskie/" target="_blank" class="logo-link">
          <img src="@/assets/images/svg/logo.svg" class="logo-image" />
        </a>
        <a :href="'tel:' + content.header.phone_link" class="phone-link">{{ content.header.phone }}</a>
      </div>
    </div>
    <div class="section-content main-section">
      <h1 class="main-page__title" v-html="content.offer.title"></h1>
      <div class="main-page__btn-row">
        <a class="test-link js-simple-modal" @click="openQuiz">{{ content.offer.text_btn }}</a>
      </div>
      <img class="main-page__rocket lazyload" src="@/assets/images/ancors/rocket.png" alt="rocket" />
      <div id="kid" class="main-page__image-wrapper" data-position="0">
        <img src="@/assets/images/ancors/kid.png" alt="kid" class="kid" />
      </div>
    </div>
  </main>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Slide_1",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  methods: {
    ...mapMutations(["openQuiz"]),
  },
};
</script>

<style>
@media (max-width: 767.98px) {
  .main-page__title br {
    display: none !important;
  }
}
</style>
