<template>
  <section class="main-challenge swiper-slide">
    <div class="section-content">
      <h2 class="main-challenge__title section-title" v-html="content.Challenge.title"></h2>
      <div class="main-challenge__content">
        <div class="steps-list" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
          <div class="steps-list__item">
            <span class="number">1</span>
            <p>{{ content.Challenge.text1 }}</p>
          </div>
          <div class="steps-list__item">
            <span class="number">2</span>
            <p>{{ content.Challenge.text2 }}</p>
          </div>
          <div class="steps-list__item">
            <span class="number">3</span>
            <p>{{ content.Challenge.text3 }}</p>
          </div>
        </div>
        <div class="btn-row" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
          <a class="main-challenge__btn btn-style js-simple-modal" @click="openPopupForm()">{{ content.Challenge.text_btn }}</a>
        </div>
      </div>
      <div class="products-wrapper">
        <img
          src="@/assets/images/items/item1.png"
          alt="product"
          class="product_image1"
          data-swiper-parallax="-200"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item2.png"
          alt="product"
          class="product_image2"
          data-swiper-parallax="-300"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item3.png"
          alt="product"
          class="product_image3"
          data-swiper-parallax="-450"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item8.png"
          alt="product"
          class="product_image4"
          data-swiper-parallax="-300"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item6.png"
          alt="product"
          class="product_image6"
          data-swiper-parallax="-250"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item9.png"
          alt="product"
          class="product_image7"
          data-swiper-parallax="-650"
          data-swiper-parallax-duration="2000" />
        <img
          src="@/assets/images/items/item5.png"
          alt="product"
          class="product_image8"
          data-swiper-parallax="-350"
          data-swiper-parallax-duration="2000" />
      </div>
    </div>
    <div class="footer">
      <div class="footer__content">
        <div class="footer__content__column social-column">
          <ul class="social-list">
            <li class="social-list__item"
              ><a :href="content.footer.social[0].social_link" class="social-list__link" target="_blank"><i class="icon icon-yt"></i></a
            ></li>
            <li class="social-list__item"
              ><a :href="content.footer.social[1].social_link" class="social-list__link" target="_blank"><i class="icon icon-vk"></i></a
            ></li>
            <li class="social-list__item"
              ><a :href="content.footer.social[2].social_link" class="social-list__link" target="_blank"><i class="icon icon-ok"></i></a
            ></li>
          </ul>
        </div>
        <div class="footer__content__column copyright-column">
          <p class="copyright">{{ content.footer.copyright }}</p>
        </div>
        <div class="footer__content__column links-column">
          <a class="doc-link js-info-modal" target="_blank" @click="openPopup">Документация</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "Slide_7",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  methods: {
    ...mapMutations(["openQuiz"]),
    openPopupForm() {
      this.$store.commit("updateStatusPopup", {
        status: "form",
        data: {},
      });
    },
    openPopup() {
      this.$store.commit("updateStatusPopup", {
        status: "info",
        data: {
          title: "Документация",
          list: this.content.footer.dop_link,
        },
      });
    },
  },
};
</script>

<style></style>
