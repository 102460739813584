<template>
  <button class="button-next-text">
    <span class="button-next-text__text">далее</span>
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L1.35355 0.646447ZM8 8L8.35355 8.35355L8.70711 8L8.35355 7.64645L8 8ZM0.646447 14.6464C0.451184 14.8417 0.451184 15.1583 0.646447 15.3536C0.841709 15.5488 1.15829 15.5488 1.35355 15.3536L0.646447 14.6464ZM0.646447 1.35355C2.01328 2.72039 3.76328 4.47039 5.17157 5.87868C5.87572 6.58283 6.49444 7.20154 6.93712 7.64423C7.15846 7.86557 7.33579 8.0429 7.45779 8.1649C7.51879 8.2259 7.56595 8.27306 7.59787 8.30497C7.61382 8.32093 7.62597 8.33308 7.63412 8.34123C7.63821 8.34532 7.64128 8.34838 7.64334 8.35045C7.64435 8.35145 7.64518 8.35228 7.64567 8.35278C7.64587 8.35297 7.64618 8.35329 7.64625 8.35336C7.64632 8.35342 7.64637 8.35347 7.6464 8.3535C7.64643 8.35354 7.64645 8.35355 8 8C8.35355 7.64645 8.35354 7.64643 8.3535 7.6464C8.35347 7.64637 8.35342 7.64632 8.35336 7.64625C8.3532 7.64609 8.35306 7.64596 8.35278 7.64567C8.35224 7.64513 8.3515 7.64439 8.35045 7.64334C8.3484 7.6413 8.34529 7.63819 8.34123 7.63412C8.33307 7.62597 8.32093 7.61383 8.30497 7.59787C8.27306 7.56595 8.22589 7.51879 8.1649 7.45779C8.0429 7.33579 7.86557 7.15846 7.64423 6.93712C7.20154 6.49444 6.58283 5.87572 5.87868 5.17157C4.47039 3.76328 2.72039 2.01328 1.35355 0.646447L0.646447 1.35355ZM7.64645 7.64645L0.646447 14.6464L1.35355 15.3536L8.35355 8.35355L7.64645 7.64645Z"
        fill="white" />
    </svg>
  </button>
</template>

<script>
export default {
  // props: ["parentClass"],
  // data() {
  //   return {
  //     classObject: {
  //       button: true,
  //       [this.parentClass]: this.parentClass,
  //     },
  //   };
  // },
};
</script>

<style>
.button-next-text {
  display: flex;
  align-items: center;
  width: 159px;
  height: 57px;
  padding-left: 50px;
  padding-right: 30px;
  border: none;
  border-radius: 30px;

  background-color: #00a7b5;

  cursor: pointer;

  transition: background-color 0.3s;
}

.button-next-text.disabled {
  background-color: #d9d9d9;
}

.button-next-text:hover {
  background-color: #00848f;
}

.button-next-text.disabled:hover {
  background-color: #d9d9d9;
}

.button-next-text__text {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.button-next-text svg {
  margin-left: 25px;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .button-next-text {
    width: 140px;
    height: 41px;
  }

  .button-next-text__text {
    font-size: 12px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .button-next-text__text {
    font-size: 11px;
  }
}
</style>
