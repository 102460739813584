<template>
  <h2 class="title-2">
    <slot />
  </h2>
</template>

<script>
export default {
};
</script>

<style>
.title-2 {
  margin-bottom: 50px;

  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  color: #383838;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
  .title-2 {
    margin-bottom: 40px;
    
    font-size: 36px;
  }
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
  .title-2 {
    margin-bottom: 35px;
    
    font-size: 30px;
  }
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
  .title-2 {
    margin-bottom: 30px;

    font-size: 28px;
  }
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .title-2 {
    margin-bottom: 25px;

    font-size: 24px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
  .title-2 {
    margin-bottom: 20px;

    font-size: 22px;
  }
}
</style>