<template>
  <div class="popup-confirmation">
    <H2 class="popup-confirmation__title" v-html="message" />
    <Input
      class="popup-confirmation__input"
      :class="errors ? 'error' : ''"
      @castom-blur="validation(code)"
      v-model="code"
      placeholder="Код или последние 4 цифры номера">
      Прислать повторно можно через:
      <a href="#" @click="newCode">запросить код повторно</a>
    </Input>
    <div class="popup-confirmation__buttons-wrapper">
      <Button class="popup-confirmation__button" @click="submitButton" target="_blank">{{ data.text_btn }}</Button>
    </div>
    <Alert />
  </div>
</template>

<script>
import H2 from "@/components/element/H2App.vue";
import Button from "@/components/element/ButtonApp.vue";
import Input from "@/components/element/InputApp.vue";
import Alert from "@/components/block/AlertApp.vue";
import { validation } from "@/helpers/index.js";

export default {
  components: {
    Button,
    H2,
    Input,
    Alert,
  },
  data() {
    return {
      data: this.$store.state.dataQuiz.quizResponse.verification,
      phone: this.$store.state.userData.phone || "",
      email: this.$store.state.userData.email || "",
      code: "",
      errors: false,
    };
  },
  computed: {
    message() {
      let arrayStr = this.data.title.split(" ");
      let phoneFormat = "";

      if (this.phone != "") {
        phoneFormat =
          "+7 (" + this.phone.slice(1, 4) + ") " + this.phone.slice(4, 7) + "-" + this.phone.slice(7, 9) + "-" + this.phone.slice(9, 12);
      }

      arrayStr[arrayStr.indexOf("!phone")] = phoneFormat;

      return arrayStr.join(" ");
    },
    getTime() {
      return this.$store.state.timeVerification;
    },
    getFormatTime() {
      return this.getTime < 10 ? "0" + this.getTime : this.getTime;
    },
  },
  methods: {
    time() {
      let context = this;

      let timer = setInterval(() => {
        if (context.$store.state.timeVerification > 0) {
          this.$store.commit("updateTime");
          return;
        }

        clearInterval(timer);
      }, 1000);
    },
    newCode() {
      if (this.getTime <= 0) {
        this.$store.dispatch("startVerification");
        this.$store.commit("startTime");
        this.time();
      }
    },
    validation(value, type = null) {
      this.errors = !validation(value, type);
    },
    submitButton() {
      let isSubmit = validation(this.code, null);

      if (isSubmit && this.$store.state.statusEnd) {
        this.$store.commit("updateCode", this.code);
        this.$store.dispatch("endVerification");
      }
    },
  },
  mounted() {
    this.time();
  },
};
</script>

<style>
.popup-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-confirmation_active {
  display: flex;
}

.popup-confirmation__close {
  position: absolute;
  top: 25px;
  right: 25px;

  width: 30px;
  height: 30px;

  cursor: pointer;
}

.popup-confirmation__close::after,
.popup-confirmation__close::before {
  content: "";

  position: absolute;
  top: 50%;
  left: 0;

  display: block;
  width: 100%;
  height: 1px;
  background-color: #929292;
}

.popup-confirmation__close::after {
  transform: rotate(45deg);
}

.popup-confirmation__close::before {
  transform: rotate(-45deg);
}

.popup-confirmation__title {
  margin-bottom: 50px;
  font-family: Lineatura, sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  margin-bottom: 60px !important;
}

.popup-confirmation__input {
  margin-bottom: 15px;
}

.popup-confirmation__buttons-wrapper {
  display: flex;
  margin-bottom: 30px;
}

.popup-confirmation__button-prev {
  margin-bottom: 50px;
  margin-right: 15px;
}

@media (max-width: 1440.98px) {
  .popup-confirmation__title {
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 40px !important;
  }
}
</style>
