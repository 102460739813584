<template>
  <Preloader v-if="this.$store.state.preloader" />
  <IndexPage v-if="this.$store.state.apiData" />
</template>

<script>
import Preloader from "@/components/section/PreloaderApp.vue";
import IndexPage from "@/views/IndexPage.vue";
export default {
  name: "App",
  components: {
    Preloader,
    IndexPage,
  },
  mounted() {
    this.$store.dispatch("loadingData");
    function scriptLoad(url,fn){
      fn = fn||function(){};
      var a=document.createElement('script');
      a.src=url;
      a.async = "async";
      a.onload=fn;
      a.onreadystatechange=fn;
      document.head.appendChild(a);
    }
    scriptLoad("https://api.flocktory.com/v2/loader.js?site_id=4460")
  },
    
};
</script>

<style></style>
