<template>
  <div class="alert" v-if="$store.state.errorVerification.size != 0">
    <ul class="alert__list">
      <template v-for="item in $store.state.errorVerification" :key="item">
        <li class="alert__item" v-if="!isErrors(item)">{{ item }}</li>
        <li class="alert__item" v-else>
          В личном кабинете Askona указан другой email для данного телефона: <a href="#" @click="returnMail">изменить email</a></li
        >
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    isErrors(item) {
      return "Номер телефона и email принадлежат разным пользователям" == item;
    },
    returnMail() {
      this.$store.commit("updateVerification", null);
      this.$store.commit("clearErrorVerification");
    },
  },
};
</script>
<style>
.alert {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 15px 15px 15px 40px;
  border-color: #f5c6cb;

  font-size: 14px;
  color: #721c24;

  background-color: #f8d7da;
}

.alert__item {
  list-style: disc;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 767.98px) {
}
</style>
