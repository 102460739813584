<template>
  <button class="item-quiz-radio">
    <div class="item-quiz-radio__check">
      <div class="item-quiz-radio__check-inner"></div>
    </div>
    <Subtitle1 class="item-quiz-radio__text" v-html="data.text_answer" />
  </button>
</template>

<script>
import Subtitle1 from "@/components/element/Subtitle1App.vue";
export default {
  name: "ItemQuizRadio",
  props: {
    data: {
      require: true,
      default: () => ({}),
    },
  },
  components: {
    Subtitle1,
  },
};
</script>

<style>
.item-quiz-radio {
  display: flex;
  align-items: center;
  width: 100%;
  height: 59px;
  padding-left: 20px;
  border: 1px solid #d4d4d4;
  border-radius: 50px;

  background-color: unset;

  cursor: pointer;
}

.item-quiz-radio__check {
  width: 21px;
  height: 21px;
  margin-right: 15px;
  border-radius: 50%;

  background-color: #d9d9d9;
}

.item-quiz-radio__text {
  width: auto;
  margin: 0;

  font-weight: 500;
  line-height: 1.6;
  color: #ffffff;
}

.item-quiz-radio.active,
.item-quiz-radio:hover {
  border-color: #00a7b5;
}

.item-quiz-radio.active .item-quiz-radio__check,
.item-quiz-radio:hover .item-quiz-radio__check {
  display: flex;
  background-color: #00a7b5;
}

.item-quiz-radio.active .item-quiz-radio__check-inner,
.item-quiz-radio:hover .item-quiz-radio__check-inner {
  width: 7px;
  height: 7px;
  margin: auto;
  border-radius: 50%;

  background-color: #ffffff;
}

/*Контейнер 1600-1920
(десктопы с большими экранами, от 1600px до 1919px)*/
@media (max-width: 1919.98px) {
}

/*Контейнер 1200-1600
(десктопы с большими экранами, от 1200px до 1600px)*/
@media (max-width: 1599.98px) {
}

/*Контейнер  -1200
(десктопы, от 992px до 1200px)*/
@media (max-width: 1199.98px) {
}

/*Контейнер 768-992
Средние устройства (планшеты, от 768px до 992px)*/
@media (max-width: 991.98px) {
  .item-quiz-radio__check {
    width: 17px;
    height: 17px;
  }
}

/*Контейнер 320-768
Компактные устройства (смартфоны в горизонтальном положении, от 576px до 768px)*/
@media (max-width: 1440.98px) {
  .item-quiz-radio {
    height: 47px;
    padding-left: 15px;
  }
}
</style>
