<template>
  <section class="main-quality swiper-slide">
    <div class="section-content">
      <h2 class="main-quality__title section-title" v-html="content.promo.title"></h2>
      <div class="quality-list" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <div class="quality-list__item" v-for="(item, index) in content.promo.advantages" :key="index">
          <a class="js-info-modal item-link" @click="openPopup({ title: item.icon_title_full, link: item.dop_link })"></a>
          <div class="icon-block"><img :src="item.icon.url" alt="icon" class="item-icon" /></div>
          <div class="descr-block">
            <p>{{ item.icon_title }}</p>
          </div>
        </div>
      </div>
      <div class="main-quality__content">
        <div id="quality-slider" class="quality-slider swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in content.promo.imgs" :key="index">
              <div class="image-wrapper mobile-image">
                <img :src="item.sizes.slide3_mobile_image" alt="type" class="slide-image lazyload" />
              </div>
              <div class="image-wrapper pad-image">
                <img :src="item.sizes.slide3_mobile_image" alt="type" class="slide-image lazyload" />
              </div>
              <div class="image-wrapper bpad-image">
                <img :src="item.sizes.slide3_bpad_image" alt="type" class="slide-image lazyload" />
              </div>
              <div class="image-wrapper pc-image">
                <img :src="item.sizes.slide3_pc_image" alt="type" class="slide-image lazyload" />
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="btn-row" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <a href="#" class="main-quality__btn btn-style" @click="openQuiz">{{ content.promo.btn }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Pagination, Autoplay, FreeMode } from "swiper";
import { mapMutations } from "vuex";

export default {
  name: "Slide_3",
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  methods: {
    ...mapMutations(["openQuiz"]),
    openPopup(data) {
      this.$store.commit("updateStatusPopup", {
        status: "info",
        data: {
          title: data.title,
          list: data.link,
        },
      });
    },
  },
  mounted() {
    new Swiper("#quality-slider", {
      modules: [Pagination, Autoplay, FreeMode],
      speed: 1500,
      slidesPerView: "auto",
      centeredSlides: true,
      loopedSlides: 2,
      spaceBetween: 0,
      loop: true,
      allowTouchMove: false,
      autoplay: {
        delay: 1000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style></style>
