<template>
  <section class="main-lifehacks swiper-slide">
    <div class="section-content">
      <h2 class="main-lifehacks__title section-title" v-html="content.history.title"></h2>
      <h3 class="main-lifehacks__subtitle" v-html="content.history.desc"></h3>
      <div class="main-lifehacks__content" data-swiper-parallax="-50" data-swiper-parallax-duration="2000">
        <div id="lifehacks-slider" class="lifehacks-slider swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in content.history['slides-history']"
              :key="index"
              @click="openPopup({ title: item['slider-title'], img: item['slider-img'].sizes.history_video, link: item['slider-link'] })">
              <div class="image-block image-block__video">
                <img :src="item['slider-img'].sizes.history" alt="slide" class="slide-image" />
                <a class="video-link video-link__form"
                  ><span><i class="icon icon-play"></i></span
                ></a>
              </div>
              <div class="descr-block">
                <div class="slide-title">{{ item["slider-title"] }}</div>
                <!-- <a class="see-link" v-if="item['slider-link']">Посмотреть лайфхак</a> -->
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Pagination } from "swiper";

export default {
  name: "Slide_6",
  mounted() {
    new Swiper("#lifehacks-slider", {
      modules: [Pagination],
      speed: 800,
      loop: false,
      slidesPerView: "auto",
      spaceBetween: 0,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  data() {
    return {
      content: this.$store.state.data.contentResponse || {},
    };
  },
  methods: {
    openPopup(data) {
      this.$store.commit("updateStatusPopup", {
        status: "video",
        data: data,
      });
    },
  },
};
</script>

<style>
.image-block__video {
  position: relative;
}

.image-block__video::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #27979d;
  opacity: 0.5;
  z-index: 111;
}
</style>
